declare var serviceWorkerOption: any;

const CACHE_NAME = "riasingle";
const CACHE_TIMEOUT = 300000; //5 Minuten

console.log(serviceWorkerOption);

self.addEventListener('activate', function (event: any) {
  event.waitUntil(
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames.filter(function (cacheName) {
          return CACHE_NAME == cacheName;
        }).map(function (cacheName) {
          return caches.delete(cacheName);
        })
      );
    })
  );
});

self.addEventListener('install', function (e: any) {
  e.waitUntil(
    caches.open(CACHE_NAME).then(function (cache) {
      return cache.addAll(serviceWorkerOption.assets);
    })
  );
});

self.addEventListener('fetch', function (event: any) {
  //TODO: Cache Requests and App Shell in different Caches
  event.respondWith(
    caches.open(CACHE_NAME).then(function (cache) {
      return cache.match(event.request).then(function (response) {

        if (!serviceWorkerOption.assets.find((x: string) => event.request.url.indexOf(x) != -1) && CACHE_TIMEOUT > 0) {
          const date = response && response.headers.get("date");
          if (!!date) {
            if ((new Date(date).getTime() + CACHE_TIMEOUT) > new Date().getTime())
              return response;
            else
              cache.delete(event.request);
          }
        } else {
          //Cached App Shell will never expire
          if (!!response) return response;
        }

        return fetch(event.request).then(function (response) {
          cache.put(event.request, response.clone());
          return response;
        });
      });
    })
  );
});

self.addEventListener('message', function (event) {
  if (event.data.action === 'skipWaiting') {
    (<any>self).skipWaiting();
  } else if (event.data.action === 'notification') {
    const data: INotificationWorkerOptions = event.data;
    console.log(data);
    if (data.timeout) {
      setTimeout(() => {
        notify(data);
      }, (+event.data.timeout) * 1000);
    } else notify(data);
  }
});

interface INotificationWorkerOptions {
  text: string;
  timeout: number;
  options: NotificationOptions;
}
const notify = (data: INotificationWorkerOptions) => {
  (<any>self).registration.showNotification(data.text, data.options);
}